<template>
  <transition name="slide">

    <div
      class="menu-slideable"
      v-show="(isHome && topOffSet > menuFadeInPoint) || !isHome || lastPage === false"
      ref="fade-in"
    >
      <button @click="onClick" ref="btn" :class="{'button-open': open || !isMenu}">
        <!-- menu -->
        <!-- L S -->
        <svg class="menu" width="65" height="18" viewBox="0 0 65 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <!-- <path class="themed-fill-colour hover-gray-stroke" d="M11 15H2V0.00799942H0V17H11V15Z" fill="white"/> -->
          <!-- <path class="themed-fill-colour hover-gray-stroke" d="M63.8054 3.608C63.0374 1.568 61.2614 0.00800085 58.5494 0.00800085C56.9414 0.00800085 55.6934 0.44 54.7814 1.328C53.8694 2.192 53.4134 3.32 53.4134 4.688C53.5094 6.704 54.2054 7.76 55.5494 8.456C56.5094 8.936 57.0854 9.104 58.2614 9.392C59.4854 9.68 59.9174 9.824 60.6614 10.136C61.0454 10.28 61.3094 10.424 61.4774 10.592C61.7894 10.904 62.0774 11.432 62.0774 12.128C62.0774 14.12 60.6614 15 58.5494 15C56.3414 15 54.9734 13.928 54.3254 11.432L52.3574 12.2C53.0054 15.44 55.3094 17 58.5494 17C61.9334 17 64.1894 15.392 64.1894 11.84C64.1174 10.088 63.4934 9.128 62.1734 8.456C61.2854 7.976 60.6374 7.784 59.4134 7.472C58.0454 7.112 57.0614 6.752 56.4374 6.392C55.8374 6.032 55.5254 5.432 55.5254 4.616C55.5254 2.984 56.7014 2.024 58.5494 2.024C60.0854 2.024 61.2134 2.864 61.9574 4.544L63.8054 3.608Z" fill="white"/> -->
        </svg>
        <!-- Lines -->
        <transition name="scroll">
          <span v-if="isScrolling === false">
            <svg class="menu lines-menu" width="65" height="18" viewBox="0 0 65 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                  <path class="custom-color" d="M20 1H44" stroke="currentColor" opacity="1" stroke-width="2"/>
                  <path class="custom-color" d="M20 8H44" stroke="currentColor" opacity="1" stroke-width="2"/>
                  <path class="custom-color" d="M20 15H44" stroke="currentColor" opacity="1" stroke-width="2"/>
              </g>
            </svg>
          </span>
        </transition>
        <!-- cross -->
        <!-- <transition name="scroll">
          <span v-if="isScrolling === true">
            <svg class="menu" width="65" height="18" viewBox="0 0 65 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33 0H31V7H24V9H31V16H33V9H40V7H33V0Z" fill="#EB5757"/>
              </g>
            </svg>
          </span>
        </transition> -->
        <!-- menu -->
        <!-- close -->
        <svg class="close" width="135" height="18" viewBox="0 0 135 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="themed-fill-colour hover-gray-stroke" d="M45.568 14.984H37V0H35V17L45.568 17V14.984Z" fill="currentColor"/>
          <path class="themed-fill-colour hover-gray-stroke" d="M98.8054 3.608C98.0374 1.568 96.2614 0.00799561 93.5494 0.00799561C91.9414 0.00799561 90.6934 0.439994 89.7814 1.32799C88.8694 2.19199 88.4134 3.31999 88.4134 4.688C88.5094 6.704 89.2054 7.76 90.5494 8.456C91.5094 8.936 92.0854 9.10399 93.2614 9.392C94.4854 9.68 94.9174 9.824 95.6614 10.136C96.0454 10.28 96.3094 10.424 96.4774 10.592C96.7894 10.904 97.0774 11.432 97.0774 12.128C97.0774 14.12 95.7334 15.176 93.6214 15.176C91.4134 15.176 89.9734 13.928 89.3254 11.432L87.3574 12.2C88.0054 15.44 90.3814 17 93.6214 17C97.0054 17 99.1894 15.392 99.1894 11.84C99.1174 10.088 98.4934 9.128 97.1734 8.456C96.2854 7.976 95.6374 7.784 94.4134 7.472C93.0454 7.11199 92.0614 6.752 91.4374 6.39199C90.8374 6.032 90.5254 5.432 90.5254 4.61599C90.5254 2.98399 91.7014 2.024 93.5494 2.024C95.0854 2.024 96.2134 2.86399 96.9574 4.54399L98.8054 3.608Z" fill="currentColor"/>
          <path class="themed-fill-colour hover-white-stroke" d="M115 17H125.896V15H117V9H124.456V7H117V2.016H125.656V0H115V17Z" fill="currentColor"/>
          <path class="themed-fill-colour hover-white-stroke" d="M61.824 2.232C60.6 3.696 60 5.832 60 8.592C60 11.352 60.6 13.488 61.824 14.976C63.048 16.44 64.608 17 66.528 17C68.448 17 70.008 16.44 71.232 14.976C72.456 13.488 73.056 11.352 73.056 8.592C73.056 5.832 72.456 3.696 71.232 2.232C70.008 0.743999 68.448 0 66.528 0C64.608 0 63.048 0.743999 61.824 2.232ZM69.696 3.624C70.488 4.68 70.872 6.336 70.872 8.592C70.872 10.848 70.488 12.504 69.696 13.584C68.904 14.64 67.848 14.976 66.528 14.976C65.208 14.976 64.152 14.64 63.36 13.584C62.568 12.504 62.184 10.848 62.184 8.592C62.184 6.336 62.568 4.68 63.36 3.624C64.152 2.544 65.208 2.016 66.528 2.016C67.848 2.016 68.904 2.544 69.696 3.624Z" fill="currentColor"/>
          <path class="themed-fill-colour hover-white-stroke" d="M17.968 11.472C17.2 13.968 16.088 15 14 15C11.024 15 9.184 13.032 9.184 8.592C9.184 4.152 11.024 2 14 2C16.088 2 17.2 3.216 17.968 5.712L19.96 5.112C19.192 2.016 17.144 0 14 0C11.984 0 10.264 0.743999 8.944 2.208C7.648 3.672 7 5.808 7 8.592C7 11.376 7.648 13.512 8.944 14.976C10.264 16.44 11.984 17 14 17C17.144 17 19.192 15.168 19.96 12.072L17.968 11.472Z" fill="currentColor"/>
        </svg>
      </button>
    </div>
  </transition>
</template>

<script>
import { throttle } from 'lodash'

export default {
  props: {
    // active: Boolean
    open: {
      type: Boolean,
      default: false
    },
    isMenu: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      isHome: true,
      isModal: false,
      isMobile: false,
      isProjects: false,
      isAbout: false,
      topOffSet: 0,
      menuFadeInPoint: 460,
      eventListenerScroll: null,
      eventListenerResize: null,
      eventListenerIsScrolling: null,
      isScrolling: false
    }
  },
  methods: {
    gbr (ref) {
      return this.$refs[ref]
    },
    onScroll () {
      // this.topOffSet = document.documentElement.scrollTop || window.pageYOffset
      // this.isMobile = (document.documentElement.clientWidth <= 600) // ? true : false
      // window.clearTimeout(this.eventListenerIsScrolling)
      // this.isScrolling = true
      // var that = this
      // this.eventListenerIsScrolling = setTimeout(() => {
      //   that.isScrolling = false
      // }, 200)
    },
    removeEvents () {
      // window.removeEventListener('scroll', this.eventListenerScroll)
      // window.removeEventListener('resize', this.eventListenerResize)
    },
    onClick () {
      if (this.isMenu) {
        this.$emit('toggle')
      } else {
        this.$emit('close')
      }
    }
  },
  watch: {
    '$route.name': function (newVal, prevVal) {
      this.isHome = false // (this.$route.name === 'home') // ? true : false
      this.isModal = (this.$route.name === 'project' || this.$route.name === 'person') // ? true : false
    },
    '$root.hasPreviousRoute': function (newVal, prevVal) {
      this.lastPage = this.$root.hasPreviousRoute
    }
  },
  created () {
    // console.log(this)
    this.throttleOnScroll = throttle(this.onScroll.bind(this), 1000)
    this.lastPage = this.$root.hasPreviousRoute
  },
  mounted () {
    // this.onScroll()
    // this.eventListenerScroll = this.$nextTick(() => {
      // window.addEventListener('scroll', this.onScroll, { passive: true })
    // })
    // this.eventListenerResize = this.$nextTick(() => {
      // window.addEventListener('resize', this.throttleOnScroll, { passive: true })
    // })
  },
  destroyed () {
    this.removeEvents()
  }
}
</script>

<style lang="scss" scoped>
@import "./../styles/theme";

  $transition-time: 0.3s;

  button {
    position: relative;
    top: 24px;
    left: 50%;
    margin-left: -32px;
    z-index: 11;
    padding: 0;
    background: transparent;
    border: none;
    font-size: 0;
    cursor: pointer;
    /* height: 24px; */
    svg {
      position: absolute;
      transition-timing-function: linear;
    }
    .menu {
      opacity: 1;
      transition-property: opacity;
      transition-duration: $transition-time;
    }
    .close {
      opacity: 0;
      transition-property: opacity;
      transition-duration: $transition-time;
      top: 0px;
      left: -35px;

      // transform: translateX(-25%);
    }
    @media (max-width: 800px) {
      top: 24px;
    }
    &:focus {
      outline: none;
    }
  }
  .button-open {
    .menu {
      opacity: 0;
    }
    .close {
      opacity: 1;
    }
  }



  /* The STD buttion colors used for evertying else - include the close BTN */
  @include themed-nav-button-hover;

  /* Custom buttion style - Inverted instead of the std color profile */
  /* The theme color chantes on the path */
  /* The hover effect happens to the group */
  /* This is so we can have two transitions and not one */
  .lines-menu {
    path { @include theme-transition; }
    g {
      @include transition-opacity;
      opacity: 1;
    }
  }

  button {
    &:hover, &:focus {
      g { opacity: 0.5; }
    }
    /* Probably correcting something on mobile but can't remember what */
    @media(hover: hover) and (pointer: fine) {
      g { opacity: 1; }
    }
  }

  @include theme('dark') {
    .custom-color { stroke: white !important; }
  }
  @include theme('light') { 
    .custom-color { stroke: black !important; }
  }
  /* End Custom buttion style */

  .menu-slideable {
    position: fixed;
    z-index: 11;
    display: flex;
    align-items: center;
    left: 0px;
    right: 0px;
  }

  .slide-enter-active, .slide-leave-active {
    transition: all .5s;
    top: 0px;
    opacity: 1;
  }
  .slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    top: -20px;
  }

  .scroll-enter-active, .scroll-leave-active {
    transition: opacity 1s;
    opacity: 1;
  }
  .scroll-enter, .scroll-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
