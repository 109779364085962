import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AspectRatio from './directives/AspectRatio'
import 'normalize.css'
import './assets/fonts/apercu.css'
import './assets/fonts/apercu-mono.css'
import VueScrollmagic from 'vue-scrollmagic'
import VueResizeText from 'vue-resize-text'

Vue.config.productionTip = false
Vue.directive('aspect-ratio', AspectRatio)

Vue.use(VueScrollmagic, {
  verical: true,
  globalSceneOptions: {},
  loglevel: 0, // 3 wll show pos in console
  refreshInterval: 100
})
Vue.use(VueResizeText)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
