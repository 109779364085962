<template>
  <nav>
    <a
      v-for="link in menu"
      :key="link.id"
      :href="link.url"
      :target="link.target"
      :title="link.title"
    >
      <svg v-if="link.classes === 'medium'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="themed-fill-colour" d="M23 1H1V23H23V1Z" fill="white"/>
        <path class="themed-fill-colour-inverted" d="M6.25 8.35767C6.26836 8.17624 6.19918 7.99692 6.0637 7.87482L4.68373 6.21242V5.9641H8.96854L12.2805 13.2276L15.1922 5.9641H19.2769V6.21242L18.0971 7.34368C17.9953 7.42121 17.9449 7.54865 17.966 7.67478V15.9867C17.9449 16.1129 17.9953 16.2403 18.0971 16.3178L19.2493 17.4491V17.6974H13.4534V17.4491L14.6471 16.2903C14.7644 16.173 14.7644 16.1385 14.7644 15.9592V9.2406L11.4456 17.6698H10.9971L7.13318 9.2406V14.89C7.10097 15.1275 7.17985 15.3666 7.34708 15.5384L8.89954 17.4215V17.6698H4.49744V17.4215L6.0499 15.5384C6.21591 15.3663 6.2902 15.1256 6.25 14.89V8.35767Z" fill="black"/>
      </svg>

      <svg v-if="link.classes === 'youtube'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="themed-fill-colour" d="M23.4864 6.62716C23.2099 5.6 22.4 4.79012 21.3728 4.51358C19.4963 4 11.9901 4 11.9901 4C11.9901 4 4.48395 4 2.60741 4.49383C1.6 4.77037 0.77037 5.6 0.493827 6.62716C0 8.5037 0 12.3951 0 12.3951C0 12.3951 0 16.3062 0.493827 18.163C0.77037 19.1901 1.58025 20 2.60741 20.2765C4.5037 20.7901 11.9901 20.7901 11.9901 20.7901C11.9901 20.7901 19.4963 20.7901 21.3728 20.2963C22.4 20.0198 23.2099 19.2099 23.4864 18.1827C23.9802 16.3062 23.9802 12.4148 23.9802 12.4148C23.9802 12.4148 24 8.5037 23.4864 6.62716ZM9.6 15.9901V8.8L15.842 12.3951L9.6 15.9901Z" fill="white"/>
      </svg>

      <svg v-if="link.classes === 'instagram'" width="24" height="24" viewBox="0 0 448 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <!-- <path class="themed-fill-colour" d="M11.9 2.20921C15.1 2.20921 15.5 2.2092 16.7 2.30962C17.9 2.41004 18.5 2.51046 18.9 2.7113C19.5 2.91213 19.9 3.21339 20.3 3.61506C20.7 4.01674 21 4.41841 21.2 5.02092C21.4 5.42259 21.6 6.12552 21.6 7.23013C21.7 8.53556 21.7 8.83682 21.7 12.0502C21.7 15.2636 21.7 15.6653 21.6 16.8703C21.5 18.0753 21.4 18.6778 21.2 19.0795C21 19.682 20.7 20.0837 20.3 20.4854C19.9 20.887 19.5 21.1883 18.9 21.3891C18.5 21.59 17.8 21.7908 16.7 21.7908C15.4 21.8912 15.1 21.8912 11.9 21.8912C8.7 21.8912 8.3 21.8912 7.1 21.7908C5.9 21.6904 5.3 21.59 4.9 21.3891C4.3 21.1883 3.9 20.887 3.5 20.4854C3.1 20.0837 2.8 19.682 2.6 19.0795C2.4 18.6778 2.2 17.9749 2.2 16.8703C2.1 15.5649 2.1 15.2636 2.1 12.0502C2.1 8.83682 2.1 8.43515 2.2 7.23013C2.3 6.0251 2.4 5.42259 2.6 5.02092C2.8 4.41841 3.1 4.01674 3.5 3.61506C3.9 3.21339 4.3 2.91213 4.9 2.7113C5.3 2.51046 6 2.30962 7.1 2.30962C8.4 2.2092 8.7 2.20921 11.9 2.20921ZM11.9 0C8.7 0 8.3 0 7 0.100418C5.7 0.100418 4.9 0.301255 4.1 0.602511C3.3 0.903766 2.7 1.30544 2 2.00837C1.3 2.7113 0.9 3.31381 0.6 4.11715C0.3 4.9205 0.1 5.72385 0.1 7.02929C0 8.33473 0 8.7364 0 11.9498C0 15.1632 0 15.5649 0.1 16.8703C0.2 18.1757 0.4 18.9791 0.7 19.7824C1 20.5858 1.4 21.2887 2.1 21.8912C2.8 22.5941 3.4 22.9958 4.2 23.2971C5 23.5983 5.8 23.7992 7.1 23.8996C8.4 24 8.8 24 12 24C15.2 24 15.6 24 16.9 23.8996C18.2 23.7992 19 23.5983 19.8 23.2971C20.6 22.9958 21.3 22.5941 21.9 21.8912C22.6 21.1883 23 20.5858 23.3 19.7824C23.6 18.9791 23.8 18.1757 23.9 16.8703C24 15.5649 24 15.1632 24 11.9498C24 8.7364 24 8.33473 23.9 7.02929C23.8 5.72385 23.6 4.9205 23.3 4.11715C23 3.31381 22.6 2.61088 21.9 2.00837C21.2 1.30544 20.6 0.903766 19.8 0.602511C19 0.301255 18.2 0.100418 16.9 0C15.6 0 15.2 0 11.9 0Z" fill="white"/>
        <path class="themed-fill-colour" d="M11.9 5.82428C8.50005 5.82428 5.80005 8.53558 5.80005 11.9498C5.80005 15.364 8.50005 18.0753 11.9 18.0753C15.3 18.0753 18 15.364 18 11.9498C18 8.53558 15.3 5.82428 11.9 5.82428ZM11.9 15.9665C9.70005 15.9665 7.90005 14.159 7.90005 11.9498C7.90005 9.7406 9.70005 7.93307 11.9 7.93307C14.1 7.93307 15.9 9.7406 15.9 11.9498C15.9 14.159 14.1 15.9665 11.9 15.9665Z" fill="white"/>
        <path class="themed-fill-colour" d="M18.3 7.02928C19.0732 7.02928 19.7 6.39985 19.7 5.62342C19.7 4.84698 19.0732 4.21756 18.3 4.21756C17.5268 4.21756 16.9 4.84698 16.9 5.62342C16.9 6.39985 17.5268 7.02928 18.3 7.02928Z" fill="white"/> -->
        <path class="themed-fill-colour" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>

      </svg>

      <svg v-if="link.classes === 'vimeo'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="themed-fill-colour" d="M21.3103 7.05665C21.2166 9.02443 19.8345 11.7418 17.1639 15.1855C14.3997 18.7462 12.0571 20.55 10.1596 20.55C8.96483 20.55 7.98094 19.449 7.16103 17.2704C6.62223 15.2557 6.06001 13.2645 5.52121 11.2499C4.91214 9.07128 4.25621 7.97026 3.55343 7.97026C3.41287 7.97026 2.87408 8.29822 1.96046 8.93073L1 7.71258C2.00732 6.82239 2.99121 5.95563 3.9751 5.06544C5.31038 3.91757 6.31769 3.3085 6.99705 3.23822C8.56659 3.09766 9.55048 4.17526 9.92529 6.471C10.3235 8.97758 10.6046 10.5237 10.7452 11.1328C11.1903 13.1943 11.7057 14.2484 12.2445 14.2484C12.6661 14.2484 13.2986 13.5691 14.1654 12.2338C15.0087 10.8985 15.4772 9.86776 15.5241 9.16499C15.6412 8.01711 15.1961 7.43146 14.1654 7.43146C13.6734 7.43146 13.1815 7.54859 12.6661 7.75943C13.6734 4.47979 15.5709 2.91025 18.3821 3.00396C20.4435 3.05081 21.4274 4.40952 21.3103 7.05665Z" fill="white"/>
      </svg>

      <svg v-if="link.classes === 'facebook'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="themed-fill-colour"  d="M21.7972 1H2.21281C1.54662 1 1 1.54637 1 2.21226V21.7877C1 22.4622 1.54662 23 2.21281 23H12.7523V14.48H9.88258V11.1591H12.7523V8.70896C12.7523 5.86612 14.4861 4.32092 17.0228 4.32092C18.2356 4.32092 19.2861 4.41482 19.5851 4.44897V7.41987H17.8256C16.4506 7.41987 16.1772 8.07722 16.1772 9.03337V11.1591H19.4655L19.0385 14.48H16.1772V23H21.7886C22.4634 23 23.0015 22.4536 23.0015 21.7877V2.21226C23.01 1.54637 22.4634 1 21.7972 1Z" fill="white"/>
      </svg>

      <svg v-if="link.classes === 'linkedin'" width="24" height="24" viewBox="0 0 448 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <!-- <path class="themed-fill-colour" d="M21.3744 1H2.62556C1.72722 1 1 1.69984 1 2.56862V21.1507C1 22.0195 1.72722 22.7193 2.62556 22.7193H21.3744C22.2667 22.7193 23 22.0195 23 21.1507V2.56862C23 1.69984 22.2667 1 21.3744 1ZM7.52667 19.5097H4.25722V9.14474H7.52056V19.5097H7.52667ZM5.895 7.72695C4.85 7.72695 4.00056 6.88834 4.00056 5.85668C4.00056 4.82501 4.84389 3.9864 5.895 3.9864C6.94 3.9864 7.78333 4.82501 7.78333 5.85668C7.78333 6.88834 6.94 7.72695 5.895 7.72695ZM19.7489 19.5097H16.4856V14.472C16.4856 13.2714 16.4672 11.7209 14.7928 11.7209C13.0939 11.7209 12.8372 13.0301 12.8372 14.3815V19.5097H9.58V9.14474H12.7089V10.5625H12.7517C13.1856 9.74805 14.2489 8.89135 15.8378 8.89135C19.1439 8.89135 19.755 11.0391 19.755 13.8265V19.5097H19.7489Z" fill="white"/> -->
        <path class="themed-fill-colour" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>

      </svg>
      <!-- <img :src="getIcon(link.classes)"> -->
    </a>
  </nav>
</template>

<script>
import Wordpress from '../mixins/Wordpress'

export default {
  mixins: [
    Wordpress
  ],
  data () {
    return {
      menu: []
    }
  },
  methods: {
    async fetchMenu () {
      this.menu = await this.$wp.getMenu('social')
    },
    getIcon (type) {
      switch (type) {
        case 'medium':
          return require('../assets/medium.svg')
        case 'youtube':
          return require('../assets/youtube.svg')
        case 'instagram':
          return require('../assets/instagram.svg')
        default:
          return null
      }
    }
  },
  beforeMount () {
    this.fetchMenu()
  }
}
</script>

<style lang="scss" scoped>
@import "./../styles/theme";
@include themed-stroke-colour;
@include themed-fill-colour;
@include themed-fill-colour-inverted;
nav {
  padding: 30px;
  padding-bottom: 6px;
  text-align: center;
  height: 24px;
}

a {
  font-size: 0;
  text-decoration: none;
}

a + a {
  margin-left: 24px;
}
</style>
