<template>
  <transition name="fade">
    <a href="#app" v-show="show">
      <span v-show="show">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path class="themed-to-top-colour" d="M16 26.6666V5.33325" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path class="themed-to-top-colour" d="M8 13.3333L16 5.33325L24 13.3333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
        </svg>
      </span>
    </a>
  </transition>
</template>
<script>
import { throttle } from 'lodash'
export default {
  data () {
    return {
      show: false,
      eventListener: null
    }
  },
  methods: {
    onScroll () {
      let windowHeight = window.innerHeight
      let scrollYOffset = document.documentElement.scrollTop || window.pageYOffset
      if (windowHeight > scrollYOffset) {
        this.show = false
      } else {
        this.show = true
      }
    }
  },
  created () {
    this.throttleOnScroll = throttle(this.onScroll.bind(this), 100)
  },
  mounted () {
    this.onScroll()
    this.eventListener = this.$nextTick(() => {
      window.addEventListener('scroll', this.throttleOnScroll, { passive: true })
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.eventListener)
  }
}
</script>
<style lang="scss" scoped>
@import "../styles/grid";
@import "../styles/theme";

@include themed-to-top-colour;

a {
  z-index: 12;
  position: fixed;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  bottom: 45px;
  right: 0px;
  margin-right: grid-pct(0.5);
  &:focus {
    outline: none;
    background: transparent;
  }
  &:hover {
    svg {
      transition: opacity .2s;
      opacity: 1;
    }
  }
  svg {
    height: 24px;
    width: 24px;
  }
  @media (max-width: 800px) {
    // top: 24px;
    margin-right: 25px;
    bottom: 25px;
    svg {
      height: 24px;
      width: 24px;
    }
  }
}

$transition-time: 0.3s;

.fade-enter-active, .fade-leave-active {
  transition: opacity $transition-time ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
