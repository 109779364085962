<template>
  <transition name="fade">
    <button @click="$emit('click', $event)">
      <svg
        v-show="colourState === 'dark' && active" class="sun"
        width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23854 14.7614 6.99996 12 6.99996C9.23858 6.99996 7 9.23854 7 12C7 14.7614 9.23858 17 12 17Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 0.999962V2.99996" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 21V23" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.21997 4.22L5.63997 5.64" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.36 18.36L19.78 19.78" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 12H3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21 12H23" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.21997 19.78L5.63997 18.36" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.36 5.64L19.78 4.22" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <svg
        v-show="colourState === 'light' && active" class="moon"
        width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21 12.79C20.5623 17.5258 16.5155 21.1036 11.7617 20.9576C7.008 20.8115 3.18847 16.992 3.0424 12.2382C2.89634 7.48448 6.47416 3.43765 11.21 3C9.15033 5.78645 9.43923 9.66045 11.8894 12.1106C14.3395 14.5607 18.2135 14.8496 21 12.79Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </transition>
</template>
<script>
export default {
  props: {
    colourState: {
      type: String,
      default: 'dark',
      validator: function (value) {
        return ['dark', 'light'].indexOf(value) !== -1
      }
    },
    active: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../styles/grid";
@import "../styles/theme";

/*
* This transition belongs to the transition tag.
* it fades in/out on theme change
*/

$transition-time: 0.3s;
.fade-enter-active, .fade-leave-active {
  transition: opacity $transition-time ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

button {
  z-index: 12;
  position: fixed;
  top: 20px;
  right:0px;
  margin-right: grid-pct(0.5);
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  @media (max-width: 800px) {
    top: 18px;
    margin-right: 26px;
  }

  &:focus {
    outline: none;
    background: transparent;
  }

  svg {
    height: 24px;
    width: 24px;
  }

  .sun {
    transition: opacity .2s;
    opacity: 0.5;
    &:hover {
      transition: opacity .2s;
      opacity: 1;
    }
  }

  .moon {
    transition: opacity .2s;
    opacity: 0.25;
    &:hover {
      transition: opacity .2s;
      opacity: 1;
    }
  }
}
</style>
