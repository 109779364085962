import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const WP_TEMPLATES = {
  home: () => import(/* webpackChunkName: "home" */ './views/Home'),
  projects: () => import(/* webpackChunkName: "projects" */ './views/Projects'),
  project: () => import(/* webpackChunkName: "project" */ './views/Project'),
  about: () => import(/* webpackChunkName: "about" */ './views/About'),
  person: () => import(/* webpackChunkName: "person" */ './views/Person'),
  work: () => import(/* webpackChunkName: "work" */ './views/Work'),
  service: () => import(/* webpackChunkName: "services" */ './views/Service'),
  contact: () => import(/* webpackChunkName: "contact" */ './views/Contact'),
  team: () => import(/* webpackChunkName: "team" */ './views/Team'),
  default: () => import(/* webpackChunkName: "default" */ './views/Default')
}

function makeRoutes (pages) {
  return pages.map(page => {
    return {
      path: page.path,
      component: WP_TEMPLATES[page.template || 'default'],
      props: true,
      name: page.template,
      beforeEnter: (to, from, next) => {
        // set history state
        if (from.matched.length === 0) {
          to.params['history'] = false
        } else {
          to.params['history'] = true
        }
        next()
      }
    }
  })
}

const routes = makeRoutes(window.bootstrapped['/light-shade/v1/routes'] || [])

export default new Router({
  mode: 'history',
  routes
})
