<template>
  <div id="app">
    <!-- v-show="showPrimaryNav" -->
    <div class="nav-box" />
    <PrimaryNavToggle
      :open="primaryNavActive"
      :isMenu="(menuType === MENU_TOGGLE)"
      @toggle="primaryNavActive = !primaryNavActive"
      @close="onMenuCloseClick"
    />
    <PrimaryNavModal
      :active="primaryNavActive"
      @close="primaryNavActive = false"
      @toggle="primaryNavActive = !primaryNavActive"
    />
    <ThemeToggle
      :active="!primaryNavActive"
      :colourState="colourState"
      @click="toggleTheme"
    />
    <NavLogo
      :active="!primaryNavActive"
      @click="onHomePressed"
    />
    <RouterView/>
    <ToTopButton
      v-if="!primaryNavActive"
    />
  </div>
</template>

<script>
import ThemeToggle from './components/ThemeToggle'
import { getLocal, setLocal } from './helpers.js'
import PrimaryNavToggle from './components/PrimaryNavToggle'
import PrimaryNavModal from './components/PrimaryNavModal'
import ToTopButton from './components/ToTopButton'
import NavLogo from './components/NavLogo'

const THEME_LIGHT = 'light'
const THEME_DARK = 'dark'
const MENU_TOGGLE = 'toggle'
const MENU_CLOSE = 'close'
export default {
  data () {
    return {
      primaryNavActive: false,
      showPrimaryNav: true,
      menuType: MENU_TOGGLE,
      colourState: getLocal('colourState', THEME_DARK),
      THEME_LIGHT: THEME_LIGHT,
      THEME_DARK: THEME_DARK,
      MENU_TOGGLE: MENU_TOGGLE
    }
  },
  watch: {
    colourState (newVal, prevVal) {
      document.body.classList.remove(prevVal)
      document.body.classList.add(newVal)
    },
    primaryNavActive (newVal, prevVal) {
      if (newVal) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'scroll'
      }
    },
    '$route.name': function (newVal, prevVal) {
      // watch the route to turn the primary nave on/off
      if (this.$route.params.history) {
        switch (newVal) {
          case 'home': this.menuType = MENU_TOGGLE; break
          case 'projects': this.menuType = MENU_TOGGLE; break
          case 'about': this.menuType = MENU_TOGGLE; break
          case 'person': this.menuType = MENU_CLOSE; break
          case 'project': this.menuType = MENU_CLOSE; break
          case 'service': this.menuType = MENU_CLOSE; break
          default: this.menuType = MENU_TOGGLE
        }
      } else {
        this.menuType = MENU_TOGGLE
      }
    }
  },
  methods: {
    onHomePressed () {
      this.$router.push('/')
    },
    toggleTheme () {
      if (this.colourState === THEME_DARK) {
        this.colourState = THEME_LIGHT
      } else {
        this.colourState = THEME_DARK
      }
      setLocal('colourState', this.colourState)
    },
    onMenuCloseClick () {
      if (this.$route.params.history) {
        this.$router.back()
      } else {
        switch (this.$route.name) {
          case 'project': this.$router.push('/projects'); break
          case 'person': this.$router.push('/about'); break
          default: this.$router.push('/home')
        }
      }
    }
  },
  created () {
    if (this.colourState === THEME_DARK) {
      document.body.classList.add(THEME_DARK)
    } else {
      document.body.classList.add(THEME_LIGHT)
    }
    this.$router.app.$on('videoModalOpen', () => { this.showPrimaryNav = false })
    this.$router.app.$on('videoModalClose', () => { this.showPrimaryNav = true })
  },
  components: {
    ThemeToggle,
    PrimaryNavToggle,
    PrimaryNavModal,
    ToTopButton,
    NavLogo
  }
}
</script>

<style lang="scss">
@import "./styles/variables";
@import "./styles/theme";
#app {
  font-family: $font-family-sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow: hidden;
}

.nav-box {
    z-index: 11;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 60px;
    /* background: red; */
  }

@include theme('light') {
  @include theme-transition;
  background-color: white;
  color: black;
}

@include theme('dark') {
  @include theme-transition;
  background-color: black;
  color: white;
}


@include theme('light') {
  .nav-box {
    @include theme-transition;
    background-color: white;
  }
}

@include theme('dark') {
  .nav-box {
    @include theme-transition;
    background-color: black;
  }
}

</style>
