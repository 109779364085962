import throttle from 'lodash/throttle'

function calcHeight (el) {
  let { width } = el.getBoundingClientRect()
  el.style.height = (width * el._aspectRatio) + 'px'
}

function onResize (el) {
  calcHeight(el)
}

export default {
  bind (el, binding) {
    el._aspectRatio = (binding.value || 1)
    el._onResize = onResize.bind(this, el)
    el._throttleOnResize = throttle(el._onResize, 200, { leading: false })

    el.addEventListener('load', el._onResize)
    window.addEventListener('resize', el._throttleOnResize, { passive: true })
  },
  inserted (el) {
    onResize(el)
  },
  update (el, binding) {
    el._aspectRatio = (binding.value || 1)
  },
  unbind (el) {
    el.removeEventListener('load', el._onResize)
    window.removeEventListener('resize', el._throttleOnResize)
  }
}
