<template>
  <transition name="fade">
    <div v-show="active" class="modal-transition-container">
      <div class="modal">
        <div class="container">
          <div class="close-spacer" />
          <PrimaryNav
            :active="active"
            @toggle="$emit('toggle', $event)"
          />
          <SocialNav />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import PrimaryNav from './PrimaryNav'
import SocialNav from './SocialNav'

export default {
  props: {
    active: Boolean
  },
  components: {
    PrimaryNav,
    SocialNav
  }
}
</script>

<style lang="scss" scoped>
@import "./../styles/theme";

$transition-time: 0.3s;

.fade-enter-active, .fade-leave-active {
  transition: opacity $transition-time ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.modal-transition-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  

  .modal {
    position: relative;
    height: 100%;
    width: 100%;
    
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      max-height: 1080px;
      padding: 40px;
      box-sizing: border-box;

      /* background-color: red; */

      @media (max-width: 800px) {
        padding: 32px;
        padding-top: 24px;
      }

      .close-spacer {
        height: 30px;
        width: 1px;
        padding-bottom: 30px;
        flex-shrink: 0;
      }
    }

  }

}

@include theme('light') {
  .modal {
    @include theme-transition;
    background-color: rgba(#fff, .9);
    color: black;
  }
}

@include theme('dark') {
  .modal {
    @include theme-transition;
    background-color: rgba(#000, .9);
    color: white;
  }
}

</style>
