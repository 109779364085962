
export function getLocal (name, def = true) {
  let item = localStorage.getItem(name)
  if (item === undefined || item === null || item === '') {
    localStorage.setItem(name, def)
    item = def
  }
  return item
}

export function setLocal (name, def) {
  localStorage.setItem(name, def)
}
