<template>
  <nav>
    <!-- Key should be on element -->
    <!-- <template v-for="(link, index) in menu"> -->
      <div 
        class="nav-link animatable" 
        v-for="(link, index) in menu"
        :key="link.ID+index"
      >
        <!-- Top Level Links -->
        <router-link
          class="link"
          v-if="link.url_relative && link.classes !== 'contact-link'"
          v-on:click.native="$emit('toggle', $event)"
          :to="link.url_relative"
          v-text="link.title"
          ref="link"
        />
        <a
          class="link"
          v-if="!link.url_relative && link.classes !== 'contact-link'"
          v-on:click.native="$emit('toggle', $event)"
          :href="link.url"
          :target="link.target"
          v-text="link.title"
          ref="link"
        />
        <!-- <router-link
          v-if="link.classes === 'contact-link'"
          v-on:click.native="scrollToContact($event)"
          :to="'/#contact'"
          v-text="link.title"
          ref="link"
        /> -->

        <!-- One Level of nested links - see parent for what is actually there -->
        <div 
          class="child-links"
          v-if="link.children.length > 0"
        >
          <template 
            v-for="(child_link, child_index) in link.children"
          >
            <router-link
              class="child-link"
              v-if="child_link.url_relative && child_link.classes !== 'contact-link'"
              v-on:click.native="$emit('toggle', $event)"
              :key="child_link.ID+'child'+child_index+'child'"
              :to="child_link.url_relative"
              v-text="child_link.title"
              ref="child_link"
            />
            <a
              class="child-link"
              v-if="!child_link.url_relative && child_link.classes !== 'contact-link'"
              v-on:click.native="$emit('toggle', $event)"
              :key="child_link.ID+'child'+child_index+'child'"
              :href="child_link.url"
              :target="child_link.target"
              v-text="child_link.title"
              ref="child_link"
            />

          </template>
        </div>
      </div>
    <!-- </template> -->
  </nav>
</template>

<script>
import Wordpress from '../mixins/Wordpress'
import { TimelineMax } from 'gsap'

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    Wordpress
  ],
  data () {
    return {
      menu: [],
      offSetTime: 0.2,
      fadeInTime: 0.5
    }
  },
  methods: {
    gbr (ref) {
      return this.$refs[ref]
    },
    async fetchMenu () {
      this.menu = await this.$wp.getMenu('primary')
      // console.log(this.menu)
    }
    // scrollToContact (event) {
    //   this.$emit('toggle', event)
    //   document.body.style.overflowY = 'scroll'
    //   document.getElementById('contact').scrollIntoView()
    // }
  },
  watch: {
    active: function (active) {
      // console.log(this)
      let $links = (!active) ? Array.from(this.$el.querySelectorAll('.animatable')) : Array.from(this.$el.querySelectorAll('.animatable')).reverse()
      const from = {
        y: (active) ? 20 : 0,
        opacity: (active) ? 0 : 1
      }
      const to = {
        y: (active) ? 0 : 0, // if menu text slides in but not out
        opacity: (active) ? 1 : 1 // if menu text should have its own opacity
        //  x: (active) ? 0 : -20, // if menu text should slide in & out
        // opacity: (active) ? 1 : 0 // if menu text should fade in & out
      }

      if (this.lineTween) {
        this.lineTween.kill()
      }

      let line = new TimelineMax()
      line.staggerFromTo($links, this.fadeInTime, from, to, this.offSetTime)
      document.body.style.overflowY = (active) ? 'hidden' : 'auto'
      document.documentElement.style.overflowY = (active) ? 'hidden' : 'auto'

      this.lineTween = line
    }
  },
  beforeMount () {
    this.fetchMenu()
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/text";
@import "./../styles/theme";

nav {
  text-align: center;
  width: fit-content;
}

.nav-link {
  width: 100%;

  .link {
    display: block;
    margin-right: (-5rem / 2);
    font-size: px(80 - 10);
    line-height: px(94/1.25) !important;
    letter-spacing: .5em;
    text-transform: uppercase;
    text-decoration: none;
    transition: color .2s;
    
    @media (max-width: 800px) {
      margin-right: -0.8rem;
      font-size: px(30);
      line-height: px(60) !important;
    }

    @media (max-height: 730px) {
      margin-right: -0.8rem;
      font-size: px(30);
      line-height: px(70) !important;
    }

    &:hover, &:focus {
      color: $red !important;
    }
  }
  .child-links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;

    @media (max-width: 700px) {
      flex-direction: column;
      margin-top: -5px;
    }

    .child-link {
      display: block;
      font-size: px(14);
      line-height: px(16) !important;
      letter-spacing: 0.1em;
      font-weight: 400;
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      opacity: 0.6;
      border-bottom: 1px solid transparent;
      transition: opacity .2s, border-bottom 0.2;

      &:hover, &:focus {
        opacity: 1 !important;
      }

      @media (max-width: 700px) {
        margin-left: auto;
        margin-right: auto;
        /* margin-top: 5px; */
      }
    }

     .child-link +  .child-link {
        margin-left: 32px;
        @media (max-width: 700px) {
          margin-left: auto;
          margin-right: auto;
          margin-top: 5px;
        }
     }
  }

}

@include theme('light') { a { color: black; } }
@include theme('dark') { a { color: white; } }

@include theme('light') { .child-link:hover, .child-link:focus {  border-bottom: 1px solid black; } }
@include theme('dark') { .child-link:hover, .child-link:focus { border-bottom: 1px solid white; } }

.nav-link + .nav-link .link {
  margin-top: 24px;

  @media (max-width: 700px) {
    margin-top: 0px;
  }
}
</style>
