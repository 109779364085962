import baseAxios from 'axios'
import { setupCache } from 'axios-cache-adapter'

const axiosCache = setupCache({
  exclude: {
    query: false
  }
})

const axios = baseAxios.create({
  adapter: axiosCache.adapter
})

async function get (url, config = {}) {
  try {
    let res = await axios.get(url, config)
    return res.data
  } catch (e) {
    console.error(e)
    return null
  }
}

async function getPageByPath (path) {
  return get('/wp-json/light-shade/v1/page-by-path', {
    params: { path }
  })
}

async function getWorkByCat (cat) {
  return get('/wp-json/light-shade/v1/work-by-filter/', {
    params: { cat }
  })
}

async function getWorkByAll () {
  return get('/wp-json/light-shade/v1/work-by-filter/', {
    params: {}
  })
}

async function getPagePreviewByPath (path) {
  let urlParams = new URLSearchParams(window.location.search)

  return get('/wp-json/light-shade/v1/page-by-path', {
    headers: {
      'X-WP-Nonce': urlParams.get('preview_nonce_api')
    },
    params: {
      path,
      preview: urlParams.get('preview'),
      preview_id: urlParams.get('preview_id'),
      preview_nonce: urlParams.get('preview_nonce')
    }
  })
}

async function getMenu (menu) {
  return get(`/wp-json/wp-api-menus/v2/menu-locations/${menu}`)
}

async function getOptionsPage (id) {
  return get(`/wp-json/acf/v3/options/${id}`)
}

async function getPosts (postType = 'posts', params) {
  return get(`/wp-json/wp/v2/${postType}`, { params })
}

async function getSidebar (sidebar) {
  return get(`/wp-json/wp-rest-api-sidebars/v1/sidebars/${sidebar}`)
}

async function delay (time = 3000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), time)
  })
}

export const IMAGE_SIZES = {
  '1536x153': '1536x153',
  '2048x2048': '2048x2048',
  'large': 'large', 
  'medium': 'medium',
  'medium_large': 'medium_large', 
  'thumbnail': 'thumbnail'
}

export const WORK_MODAL_TYPES = {
  'vimeo': 'vimeo',
  'photography': 'photography'
}


export default {
  beforeCreate () {
    this.$wp = {
      getWorkByCat,
      getWorkByAll,
      getPageByPath,
      getPagePreviewByPath,
      getMenu,
      getOptionsPage,
      getPosts,
      getSidebar,
      delay,
      imageSizes: IMAGE_SIZES,
      workTypes: {
        modal: WORK_MODAL_TYPES
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scroll(0, 0)
      // this makes a copy on pages
      vm.prevRoute = from
      vm.hasPreviousRoute = (from.name !== null)
      // this makes it avalible to all components
      vm.$root.hasPreviousRoute = (from.name !== null)
      vm.$root.prevRoute = (from.name !== null)
    })
  }
}
