<template>
  <transition name="fade">
    <button  @click="$emit('click', $event)">
      <svg
        v-show="active"
        width="81" height="30" viewBox="0 0 81 30" fill="none" xmlns="http://www.w3.org/2000/svg"
      >
        <title>Lgiht and Shade</title>
        <path class="themed-fill-colour" d="M14.0478 20.984H5.47978V6.2H3.36778V23H14.0478V20.984ZM66.8054 9.608C66.0374 7.568 64.2614 6.008 61.5494 6.008C59.9414 6.008 58.6934 6.44 57.7814 7.328C56.8694 8.192 56.4134 9.32 56.4134 10.688C56.5094 12.704 57.2054 13.76 58.5494 14.456C59.5094 14.936 60.0854 15.104 61.2614 15.392C62.4854 15.68 62.9174 15.824 63.6614 16.136C64.0454 16.28 64.3094 16.424 64.4774 16.592C64.7894 16.904 65.0774 17.432 65.0774 18.128C65.0774 20.12 63.7334 21.176 61.6214 21.176C59.4134 21.176 57.9734 19.928 57.3254 17.432L55.3574 18.2C56.0054 21.44 58.3814 23.192 61.6214 23.192C65.0054 23.192 67.1894 21.392 67.1894 17.84C67.1174 16.088 66.4934 15.128 65.1734 14.456C64.2854 13.976 63.6374 13.784 62.4134 13.472C61.0454 13.112 60.0614 12.752 59.4374 12.392C58.8374 12.032 58.5254 11.432 58.5254 10.616C58.5254 8.984 59.7014 8.024 61.5494 8.024C63.0854 8.024 64.2134 8.864 64.9574 10.544L66.8054 9.608Z" fill="currentColor"/>
        <path d="M31.0705 13.0705H24V15.9295H31.0705V23H33.9295V15.9295H41V13.0705H33.9295V6H31.0705V13.0705Z" fill="#EB5757"/>
      </svg>

      
      <!-- <svg
        v-show="colourState === 'dark' && active" class="sun"
        width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23854 14.7614 6.99996 12 6.99996C9.23858 6.99996 7 9.23854 7 12C7 14.7614 9.23858 17 12 17Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 0.999962V2.99996" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 21V23" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.21997 4.22L5.63997 5.64" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.36 18.36L19.78 19.78" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 12H3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21 12H23" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.21997 19.78L5.63997 18.36" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.36 5.64L19.78 4.22" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <svg
        v-show="colourState === 'light' && active" class="moon"
        width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21 12.79C20.5623 17.5258 16.5155 21.1036 11.7617 20.9576C7.008 20.8115 3.18847 16.992 3.0424 12.2382C2.89634 7.48448 6.47416 3.43765 11.21 3C9.15033 5.78645 9.43923 9.66045 11.8894 12.1106C14.3395 14.5607 18.2135 14.8496 21 12.79Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg> -->
    </button>
  </transition>
</template>
<script>
export default {
  props: {
    colourState: {
      type: String,
      default: 'dark',
      validator: function (value) {
        return ['dark', 'light'].indexOf(value) !== -1
      }
    },
    active: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../styles/grid";
@import "../styles/theme";

/*
* This transition belongs to the transition tag.
* it fades in/out on theme change
*/

$transition-time: 0.3s;
.fade-enter-active, .fade-leave-active {
  transition: opacity $transition-time ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

button {
  z-index: 12;
  position: fixed;
  top: 18px;
  left:0px;
  margin-left: grid-pct(0.5);
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  @media (max-width: 800px) {
    top: 18px;
    margin-left: 26px;
  }

  &:focus {
    outline: none;
    background: transparent;
  }

  svg {
    height: 30px;
    width: 81px;
  }

  .sun {
    transition: opacity .2s;
    opacity: 0.5;
    &:hover {
      transition: opacity .2s;
      opacity: 1;
    }
  }

  .moon {
    transition: opacity .2s;
    opacity: 0.25;
    &:hover {
      transition: opacity .2s;
      opacity: 1;
    }
  }
}

@include themed-fill-colour;
@include themed-nav-button-hover;

</style>
